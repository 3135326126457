import React from "react";
import { Helmet } from "react-helmet";
import { createGlobalStyle } from "styled-components";
import { ThemeProvider } from "styled-components";
import image from '../assets/index.png';

interface PageProps {
  children: React.ReactNode;
}

const GlobalStyles = createGlobalStyle`
  body {
    background: black;
    color: white;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;
  }

  body::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }

  html {
    font-family: "Josefin Sans Regular",-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    font-size: 10px;
    -webkit-font-smoothing: antialiased;  
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    position: relative;
    margin: 0;
    padding: 0;
    font-weight: normal;
    line-height: normal;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  a * {
    transition: all .1s ease;
  }

  /* THE FOLLOWING IS A CSS RESET FROM https://github.com/hankchizljaw/modern-css-reset */

  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Set core root defaults */
  html {
    scroll-behavior: smooth;
  }

  /* Set core body defaults */
  body {
    min-height: 100vh;
  }

  /* Make images easier to work with */
  img,
  picture {
    max-width: 100%;
    display: block;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }
`;

export const Page = ({ children }: PageProps) => {
  const theme = {
    color: {
      background: "#000000",
      foreground: "#ffffff",
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Helmet htmlAttributes={{ lang: "en" }} title="Peter Khlopenkov">
        <meta property="og:title" content="Peter Khlopenkov" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://khlopenkov.com" />
        <meta property="og:image" content={"https://khlopenkov.com" + image} />
        <meta property="og:image:width" content="2400" />
        <meta property="og:image:width" content="1260" />
        <link rel="preload" href="fonts/JosefinSans-Bold.woff" as="font" type="font/woff" />
      </Helmet>
      <GlobalStyles />
      <main>{children}</main>
    </ThemeProvider>
  );
};
