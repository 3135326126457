import React from "react";

export const Youtube = () => {
  return (
    <svg fill="white" width="24" height="17" viewBox="0 0 24 17" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.2558 3.12846C22.9899 2.14304 22.2066 1.36696 21.212 1.10358C19.4093 0.625 12.1805 0.625 12.1805 0.625C12.1805 0.625 4.95168 0.625 3.14889 1.10358C2.1543 1.367 1.37098 2.14304 1.10512 3.12846C0.62207 4.91458 0.62207 8.64117 0.62207 8.64117C0.62207 8.64117 0.62207 12.3678 1.10512 14.1539C1.37098 15.1393 2.1543 15.883 3.14889 16.1464C4.95168 16.625 12.1805 16.625 12.1805 16.625C12.1805 16.625 19.4092 16.625 21.212 16.1464C22.2066 15.883 22.9899 15.1393 23.2558 14.1539C23.7389 12.3678 23.7389 8.64117 23.7389 8.64117C23.7389 8.64117 23.7389 4.91458 23.2558 3.12846V3.12846ZM9.81623 12.0246V5.25771L15.8581 8.64125L9.81623 12.0246V12.0246Z" />
    </svg>
  );
};
