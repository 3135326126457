import React from "react";
import styled from "styled-components";
import { Chevron } from "../components/Chevron/Chevron";

const PageContainer = styled.div`
  width: 100vw;

  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

const MainContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-flow: column;
`;

const Title = styled.h1`
  height: 4.2rem;
  font-size: 5.6rem;
`;

const MainContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  padding: 20px;
`;

const GradientBar = styled.div`
  height: 20px;
  background: linear-gradient(to right, #FF6CB3, #FF6666);
  width: 100%;
`;

const TopTitle = styled(Title)`
  position: relative;
  width: max-content;
  font-family: "Josefin Sans Semi Bold";
`;

const BottomTitle = styled(Title)`
  font-family: "Josefin Sans Thin";
`;

const TitleContainer = styled.div`
  display: grid;
  row-gap: 10px;
  padding-bottom: 20px;
`;

const TitleGradientBar = styled.div`
  height: 14px;
  background: linear-gradient(to right, #FF6CB3, #FF6666);
  width: 100%;
`

const Description = styled.p`
  font-size: 1.4rem;
  line-height: 120%;
  padding-top: 14px;
`;

const ListContainer = styled.div`
  margin: 54px 0;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-image-source: linear-gradient(90deg, #FF6CB3 0%, #FF6666 100%);
  border-image-slice: 1;
`;

const ListItemText = styled.p`
  font-size: 2rem;
`;

const ChevronContainer = styled.div`
  transform: scale(1.4) rotate(0.25turn); 
`;

export function Mobile() {
  return (
    <PageContainer>
      <MainContainer>
        <GradientBar />
        <MainContentContainer>
          <TitleContainer>
            <TopTitle>Peter</TopTitle>
            <BottomTitle>Khlopenkov</BottomTitle>
          </TitleContainer>
          <TitleGradientBar />
          <Description>
            Front-end developer working on Shopify’s checkout.
          </Description>
        </MainContentContainer>
      </MainContainer>
      {/* <ListContainer>
        <ListItem>
          <ListItemText>web development</ListItemText>
          <ChevronContainer>
            <Chevron />
          </ChevronContainer>
        </ListItem>
        <ListItem>
          <ListItemText>projects</ListItemText>
          <ChevronContainer>
            <Chevron />
          </ChevronContainer>
        </ListItem>
      </ListContainer> */}
      {/* <GradientBar /> */}
    </PageContainer >
  );
}
