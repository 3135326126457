import React, { useEffect, useState } from "react";
import styled, { css, Keyframes, keyframes } from "styled-components";
import { BackChevron } from "../components/BackChevron/BackChevron";
import { Chevron } from "../components/Chevron/Chevron";
import { Github } from "../components/Github/Github";
import { Instagram } from "../components/Instagram/Instagram";
import { Youtube } from "../components/Youtube/Youtube";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const expandProjectsList = keyframes`
  from {
    max-height: 0px;
  }

  to {
    max-height: calc(18px + 1.4rem + 18px + 1.4rem);
  }
`;

const expandWebDevelopmentList = keyframes`
  from {
    max-height: 0px;
  }

  to {
    max-height: calc(18px + 1.4rem);
  }
`;

const contractWebDevelopmentList = keyframes`
  from {
    max-height: calc(18px + 1.4rem);
  }

  to {
    max-height: 0px;
  }
`;


const contractProjectsList = keyframes`
  from {
    max-height: calc(18px + 1.4rem + 18px + 1.4rem);
  }

  to {
    max-height: 0px;
  }
`;

const rotateChevron = keyframes`
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(90deg);
  }
`;

const rotateChevronBack = keyframes`
  from {
    transform: rotate(90deg);
  }

  to {
    transform: rotate(0);
  }
`;

const verticalSlideIn = keyframes`
  to {
    transform: translateY(0);
  }
`;

const verticalSlideOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
`;

const horizontalSlideIn = keyframes`
  to {
    transform: translateX(0);
  }
`;

const horizontalSlideOut = keyframes`
  from {
    transform: translateX(0);
  }  
  to {
    transform: translateX(-100%);
  }
`;

const horizontalSlideOutRight = keyframes`
  from {
    transform: translateX(0);
  }  
  to {
    transform: translateX(100%);
  }
`;

const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  background: linear-gradient(to right, #FF6CB3, #FF6666);
  overflow-x: hidden;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const ContentContainer = styled.div`
  width: min-content;
  position: relative;
`;

const TitleContainer = styled.div`
  display: grid;
  row-gap: 10px;
  padding: 24px 0px;
`;

const TitleAnimationContainer = styled.div`
clip-path: inset(-9999px 0);

`;
const Circle = styled.div`
  position: absolute;
  border-radius: 50%;
`;

const Circle1 = styled(Circle)`
  top: -304px;
  left: -450px;
  width: 294px;
  height: 294px;
  background: linear-gradient(90deg, #FF666600 0%, #FF6666 100%);
`;

const Circle2 = styled(Circle)`
  bottom: -346px;
  left: -326px;
  width: 168px;
  height: 168px;
  background: linear-gradient(90deg, #CD8DFF00 0%, #CD8DFF 100%);
`;

const Circle3 = styled(Circle)`
  bottom: -210px;
  right: -204px;
  width: 86px;
  height: 86px;
  background: linear-gradient(90deg, #6AEDFF00 0%, #6AEDFF 100%);
`;

const Circle4 = styled(Circle)`
  top: -196px;
  right: -464px;
  width: 156px;
  height: 156px;
  background: linear-gradient(90deg, #FF6CB300 0%, #FF6CB3 100%);
`;

const Title = styled.h1`
  height: 4.8rem;
  font-size: 6.4rem;
`;

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const TopTitle = styled(Title)`
  position: relative;
  width: max-content;
  font-family: "Josefin Sans Semi Bold";
`;

const BottomTitle = styled(Title)`
  font-family: "Josefin Sans Thin";
`;
interface AnimationProps {
  animationToggle: boolean;
  animationDelay?: number;
}

const animationRule = (inAnimation: Keyframes, outAnimation: Keyframes, fixedDuration?: boolean) => css<AnimationProps>`
  ${({ animationToggle }) => animationToggle ? inAnimation : outAnimation} ${({ animationToggle }) => animationToggle || fixedDuration ? '0.5s' : '0.25s'} ease ${({ animationToggle, animationDelay = 0 }) => animationToggle ? animationDelay : 0}s forwards
`;

const TitleAnimation = styled.div<AnimationProps>`
  transform: translateX(-100%);
  animation: ${animationRule(horizontalSlideIn, horizontalSlideIn)}, ${animationRule(fadeIn, fadeOut)};
`

const BottomContainer = styled.div`
  height: 0;
`

const DescriptionContainer = styled.div`
  display: grid;
  row-gap: 16px;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 1.4rem;
  line-height: 120%;
`;

const DescriptionAnimationContainer = styled.div`
  clip-path: inset(0);
`

const DescriptionAnimation = styled.div<AnimationProps>`
  transform: translateY(-100%);
  animation: ${animationRule(verticalSlideIn, verticalSlideIn)}, ${animationRule(fadeIn, fadeOut)};
`

const ListContainer = styled.div`
  clip-path: inset(0);
  display: grid;
  row-gap: 18px;
`;

const ProjectsList = styled.div<AnimationProps>`
  display: grid;
  max-height: 0px;
  animation: ${animationRule(expandProjectsList, contractProjectsList, true)};
`;

const WebDevelopmentList = styled.div<AnimationProps>`
  display: grid;
  max-height: 0px;
  animation: ${animationRule(expandWebDevelopmentList, contractWebDevelopmentList, true)};
`;

const SubListContainer = styled(ListContainer)`
  margin-left: 24px;
  margin-top: 18px;
`;

interface ListItemAnimationProps extends AnimationProps {
  index: number;
};

const listItemSlideOut = (transform: string) => keyframes`
  from {
    transform: translateY(0);
  }
  to {
    ${transform}
  }
`;

const listItemTransform = css<ListItemAnimationProps>`
  transform: translateY(calc(-117% + (-117% - 18px) * ${props => props.index}));
`;

const ListItemAnimation = styled.div<ListItemAnimationProps>`
  ${listItemTransform}
  animation: ${animationRule(verticalSlideIn, verticalSlideIn)}, ${animationRule(fadeIn, fadeOut)};
`

const SubListItemAnimation = styled.div<ListItemAnimationProps>`
  ${listItemTransform}
  animation: ${animationRule(verticalSlideIn, listItemSlideOut(`${listItemTransform}`), true)};
`

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ListItemText = styled.p`
  height: 1.4rem;
  font-size: 1.4rem;
  margin-left: 16px;
`;

interface ChevronContainerProps {
  maintainPosition?: boolean;
}

const ChevronContainer = styled.div<ChevronContainerProps>`
  ${props => props.maintainPosition && `transform: translateX(6px);`}
`;

const ProjectsChevronContainer = styled.div<AnimationProps>`
  animation: ${animationRule(rotateChevron, rotateChevronBack)};
`;

const SocialLinksContainer = styled.div`
  width: fit-content;
  display: grid;
  grid-auto-flow: column;
  column-gap: 16px;
  clip-path: inset(0);
  margin-top: 32px;
`;

const Link = styled.a`
  cursor: pointer;

  &:hover * {
    color: #DC1F56;
    fill: #DC1F56;
  }

  &:hover ${ChevronContainer} {
    transform: translateX(6px);
  }
`

const BackChevronContainer = styled.div`
  position: absolute;
  top: 50%;
  left: calc(-15px - 20px);
  transform: translate(0,-50%);
`

const BackChevronAnimationContainer = styled.div`
  clip-path: inset(-1px);
`

const BackChevronAnimation = styled.div<AnimationProps>`
  transform: translateX(100%);
  animation: ${animationRule(horizontalSlideIn, horizontalSlideOutRight)}, ${animationRule(fadeIn, fadeOut)};
`

const BackLink = styled(Link)`
  &:hover ${BackChevronAnimationContainer} {
    transform: translateX(-6px);
  }
`

const SkeletonContainer = styled.div`
  visibility: hidden;
  z-index: -99999;
`

interface SocialLinkAnimationProps extends AnimationProps {
  transform: number;
};

const SocialLinkAnimation = styled.div<SocialLinkAnimationProps>`
  transform: translateX(${({ transform }) => transform}px);
  animation: ${animationRule(horizontalSlideIn, horizontalSlideIn)}, ${animationRule(fadeIn, fadeOut)};
`

interface IndexProps {
  route: string;
}

export function Index({ route }: IndexProps) {
  const [displayProjects, setDisplayProjects] = useState(false);
  const [projectsExpanded, setProjectsExpanded] = useState(false);
  const [displayWebDevelopment, setDisplayWebDevelopment] = useState(false);
  const [webDevelopmentExpanded, setWebDevelopmentExpanded] = useState(false);
  const [currentPath, setCurrentPath] = useState(route);
  const [displayIndex, setDisplayIndex] = useState(route === '');
  const [displayRapbeat, setDisplayRapbeat] = useState(route === 'rapbeat');
  const [displayCoral, setDisplayCoral] = useState(route === 'coral');
  const [displayTheVeldt, setDisplayTheVeldt] = useState(route === 'the-veldt');

  function projectsClicked() {
    if (projectsExpanded) {
      setProjectsExpanded(false);
      setTimeout(() => {
        setDisplayProjects(projectsExpanded);
      }, 500)
    } else {
      setProjectsExpanded(true);
      setDisplayProjects(true);
    }
  }

  function webDevelopmentClicked() {
    if (webDevelopmentExpanded) {
      setWebDevelopmentExpanded(false);
      setTimeout(() => {
        setDisplayWebDevelopment(webDevelopmentExpanded);
      }, 500)
    } else {
      setWebDevelopmentExpanded(true);
      setDisplayWebDevelopment(true);
    }
  }

  function coralClicked() {
    setProjectsExpanded(false);
    setWebDevelopmentExpanded(false);
    history.pushState({ fromHome: true }, '', 'coral');
    setCurrentPath('coral');
    setTimeout(() => {
      setDisplayCoral(true);
      setDisplayIndex(false);
      setDisplayProjects(false);
      setDisplayWebDevelopment(false);
    }, 250);
  }

  function rapbeatClicked() {
    setProjectsExpanded(false);
    setWebDevelopmentExpanded(false);
    history.pushState({ fromHome: true }, '', 'rapbeat');
    setCurrentPath('rapbeat');
    setTimeout(() => {
      setDisplayRapbeat(true);
      setDisplayIndex(false);
      setDisplayProjects(false);
      setDisplayWebDevelopment(false);
    }, 250);
  }

  function theVeldtClicked() {
    setProjectsExpanded(false);
    setWebDevelopmentExpanded(false);
    history.pushState({ fromHome: true }, '', 'the-veldt');
    setCurrentPath('the-veldt');
    setTimeout(() => {
      setDisplayTheVeldt(true);
      setDisplayIndex(false);
      setDisplayProjects(false);
      setDisplayWebDevelopment(false);
    }, 250);
  }

  function goBack() {
    setCurrentPath('');
    setTimeout(() => {
      setDisplayRapbeat(false);
      setDisplayCoral(false);
      setDisplayTheVeldt(false);
      setDisplayIndex(true);
    }, 250);
  }

  function backClicked() {
    if (history.state?.fromHome) {
      history.back();
    } else {
      history.replaceState({}, '', '/');
      goBack();
    }
  }


  useEffect(() => {
    window.onpopstate = () => {
      goBack();
    };
  });

  return (
    <PageContainer>
      <ContentContainer>
        <Circle1 />
        <Circle2 />
        <Circle3 />
        <Circle4 />
        <TitleContainer>
          <TopTitle>
            {(displayRapbeat || displayCoral || displayTheVeldt) &&
              <BackChevronContainer>
                <BackLink onClick={backClicked}>
                  <BackChevronAnimationContainer>
                    <BackChevronAnimation animationToggle={currentPath === 'rapbeat' || currentPath === 'coral' || currentPath === 'the-veldt'}>
                      <BackChevron />
                    </BackChevronAnimation>
                  </BackChevronAnimationContainer>
                </BackLink>
              </BackChevronContainer>
            }
            {displayIndex &&
              <OverlayContainer>
                <TitleAnimationContainer><TitleAnimation animationToggle={currentPath === ''}>Peter</TitleAnimation></TitleAnimationContainer>
              </OverlayContainer>
            }
            {(displayRapbeat || displayCoral || displayTheVeldt) &&
              <OverlayContainer>
                <TitleAnimationContainer><TitleAnimation animationToggle={currentPath === 'rapbeat' || currentPath === 'coral' || currentPath === 'the-veldt'}>Project</TitleAnimation></TitleAnimationContainer>
              </OverlayContainer>
            }
          </TopTitle>
          <BottomTitle>
            <SkeletonContainer>
              Khlopenkov
            </SkeletonContainer>
            {displayIndex &&
              <OverlayContainer>
                <TitleAnimationContainer><TitleAnimation animationToggle={currentPath === ''} animationDelay={0.05}>Khlopenkov</TitleAnimation></TitleAnimationContainer>
              </OverlayContainer>
            }
            {displayRapbeat &&
              <OverlayContainer>
                <TitleAnimationContainer><TitleAnimation animationToggle={currentPath === 'rapbeat'} animationDelay={0.05}>RapBeat</TitleAnimation></TitleAnimationContainer>
              </OverlayContainer>
            }
            {displayCoral &&
              <OverlayContainer>
                <TitleAnimationContainer><TitleAnimation animationToggle={currentPath === 'coral'} animationDelay={0.05}>Coral</TitleAnimation></TitleAnimationContainer>
              </OverlayContainer>
            }
            {displayTheVeldt &&
              <OverlayContainer>
                <TitleAnimationContainer><TitleAnimation animationToggle={currentPath === 'the-veldt'} animationDelay={0.05}>The Veldt</TitleAnimation></TitleAnimationContainer>
              </OverlayContainer>
            }
          </BottomTitle>
        </TitleContainer>
        <BottomContainer>
          <DescriptionContainer>
            {displayIndex &&
              <DescriptionAnimationContainer>
                <DescriptionAnimation animationToggle={currentPath === ''} animationDelay={0.2}>
                  <Description>
                    Front-end developer working on Shopify’s checkout.
                  </Description>
                </DescriptionAnimation>
              </DescriptionAnimationContainer>
            }
            {displayRapbeat &&
              <>
                <DescriptionAnimationContainer>
                  <DescriptionAnimation animationToggle={currentPath === 'rapbeat'} animationDelay={0.2}>
                    <Description>
                      RapBeat is an app I built for android in early 2017. It allows you to create your own beat by mixing together 6 different instruments, each with 5 unique samples and patterns to choose from.
                    </Description>
                  </DescriptionAnimation>
                </DescriptionAnimationContainer>
                <DescriptionAnimationContainer>
                  <DescriptionAnimation animationToggle={currentPath === 'rapbeat'} animationDelay={0.4}>
                    <Description>
                      Soon after its release, RapBeat became a big hit in the android music production scene. It amassed over 80,000 downloads with an average play store rating of 4.4. To capitilize on its success, I added an in-app purchase that allows you to record your beat.
                    </Description>
                  </DescriptionAnimation>
                </DescriptionAnimationContainer>
              </>
            }
            {displayCoral &&
              <DescriptionAnimationContainer>
                <DescriptionAnimation animationToggle={currentPath === 'coral'} animationDelay={0.2}>
                  <Description>
                    Coral is the codename for my personal website. I designed this website in Figma, and built it in React using gatsby.js with TypeScript and styled components.
                  </Description>
                </DescriptionAnimation>
              </DescriptionAnimationContainer>
            }
            {displayTheVeldt &&
              <>
                <DescriptionAnimationContainer>
                  <DescriptionAnimation animationToggle={currentPath === 'the-veldt'} animationDelay={0.2}>
                    <Description>
                      The Veldt is a mobile game I built using the Unity game engine in 2018. It is an open-world puzzle game set in a mysterious african savanna.
                    </Description>
                  </DescriptionAnimation>
                </DescriptionAnimationContainer>
                <DescriptionAnimationContainer>
                  <DescriptionAnimation animationToggle={currentPath === 'the-veldt'} animationDelay={0.4}>
                    <Description>
                      The vision for the game started in Photoshop where I came up with the main setting and art style. When I realized I wanted to make this setting come alive, I learned to use Unity and turned it into a mobile game.
                    </Description>
                  </DescriptionAnimation>
                </DescriptionAnimationContainer>
              </>
            }
          </DescriptionContainer>
          {displayIndex &&
            <ListContainer>
              <ListItemAnimation index={0} animationToggle={currentPath === ''} animationDelay={0.6}>
                <Link onClick={webDevelopmentClicked}>
                  <ListItem >
                    <ChevronContainer maintainPosition={webDevelopmentExpanded && currentPath === ''}>
                      <ProjectsChevronContainer animationToggle={webDevelopmentExpanded && currentPath === ''}>
                        <Chevron />
                      </ProjectsChevronContainer>
                    </ChevronContainer>
                    <ListItemText>web development</ListItemText>
                  </ListItem>
                </Link>
                {displayWebDevelopment &&
                  <WebDevelopmentList animationToggle={webDevelopmentExpanded && currentPath === ''}>
                    <SubListContainer>
                      <SubListItemAnimation index={0} animationToggle={webDevelopmentExpanded && currentPath === ''} animationDelay={0}>
                        <Link onClick={coralClicked}>
                          <ListItem >
                            <ChevronContainer>
                              <Chevron />
                            </ChevronContainer>
                            <ListItemText>khlopenkov.com</ListItemText>
                          </ListItem>
                        </Link>
                      </SubListItemAnimation>
                    </SubListContainer>
                  </WebDevelopmentList>
                }
              </ListItemAnimation>
              <ListItemAnimation index={1} animationToggle={currentPath === ''} animationDelay={0.5}>
                <Link onClick={projectsClicked}>
                  <ListItem >
                    <ChevronContainer maintainPosition={projectsExpanded && currentPath === ''}>
                      <ProjectsChevronContainer animationToggle={projectsExpanded && currentPath === ''}>
                        <Chevron />
                      </ProjectsChevronContainer>
                    </ChevronContainer>
                    <ListItemText>other projects</ListItemText>
                  </ListItem>
                </Link>
                {displayProjects &&
                  <ProjectsList animationToggle={projectsExpanded && currentPath === ''}>
                    <SubListContainer>
                      <SubListItemAnimation animationToggle={projectsExpanded && currentPath === ''} index={0} animationDelay={0.1}>
                        <Link onClick={rapbeatClicked}>
                          <ListItem >
                            <ChevronContainer>
                              <Chevron />
                            </ChevronContainer>
                            <ListItemText>RapBeat</ListItemText>
                          </ListItem>
                        </Link>
                      </SubListItemAnimation>
                      <SubListItemAnimation animationToggle={projectsExpanded && currentPath === ''} index={1}>
                        <Link onClick={theVeldtClicked}>
                          <ListItem >
                            <ChevronContainer>
                              <Chevron />
                            </ChevronContainer>
                            <ListItemText>The Veldt</ListItemText>
                          </ListItem>
                        </Link>
                      </SubListItemAnimation>
                    </SubListContainer>
                  </ProjectsList>
                }
              </ListItemAnimation>
              <ListItemAnimation index={2} animationToggle={currentPath === ''} animationDelay={0.4}>
                <Link href="./resume.pdf" target="_blank">
                  <ListItem >
                    <ChevronContainer>
                      <Chevron />
                    </ChevronContainer>
                    <ListItemText>resume</ListItemText>
                  </ListItem>
                </Link>
              </ListItemAnimation>
            </ListContainer>
          }
          {displayRapbeat &&
            <ListContainer>
              <ListItemAnimation index={0} animationToggle={currentPath === 'rapbeat'} animationDelay={0.6}>
                <Link href="https://play.google.com/store/apps/details?id=peterkhlopenkov.rapbeat" target="__blank">
                  <ListItem >
                    <ChevronContainer>
                      <Chevron />
                    </ChevronContainer>
                    <ListItemText>playstore listing</ListItemText>
                  </ListItem>
                </Link>
              </ListItemAnimation>
            </ListContainer>
          }
          {displayTheVeldt &&
            <ListContainer>
              <ListItemAnimation index={0} animationToggle={currentPath === 'the-veldt'} animationDelay={0.6}>
                <Link href="https://play.google.com/store/apps/details?id=com.peterkhlopenkov.the_veldt" target="__blank">
                  <ListItem >
                    <ChevronContainer>
                      <Chevron />
                    </ChevronContainer>
                    <ListItemText>playstore listing</ListItemText>
                  </ListItem>
                </Link>
              </ListItemAnimation>
              <ListItemAnimation index={1} animationToggle={currentPath === 'the-veldt'} animationDelay={0.5}>
                <Link href="https://www.youtube.com/watch?v=puizszGJKY4" target="__blank">
                  <ListItem >
                    <ChevronContainer>
                      <Chevron />
                    </ChevronContainer>
                    <ListItemText>gameplay footage</ListItemText>
                  </ListItem>
                </Link>
              </ListItemAnimation>
            </ListContainer>
          }
          {displayIndex &&
            <SocialLinksContainer>
              <SocialLinkAnimation animationToggle={currentPath === ''} transform={-18} animationDelay={0.8}>
                <Link href="https://github.com/khl" target="__blank">
                  <Github />
                </Link>
              </SocialLinkAnimation>
              <SocialLinkAnimation animationToggle={currentPath === ''} transform={-58} animationDelay={0.7}>
                <Link href="https://www.youtube.com/channel/UCQq-DrADr3mpfC-q0NDCVEA" target="__blank">
                  <Youtube />
                </Link>
              </SocialLinkAnimation>
              <SocialLinkAnimation animationToggle={currentPath === ''} transform={-90} animationDelay={0.6}>
                <Link href="https://instagram.com/peterkhlopenkov" target="__blank">
                  <Instagram />
                </Link>
              </SocialLinkAnimation>
            </SocialLinksContainer>
          }
        </BottomContainer>
      </ContentContainer>
    </PageContainer >
  );
}
