import React from "react";

export const Chevron = () => {
  return (
    <svg
      width="8"
      height="10"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      display="block"
    >
      <path
        d="M2.08991 10L0.910767 8.82082L4.73159 5L0.910767 1.17918L2.08991 0L7.08991 5L2.08991 10Z"
        fill="white"
      />
    </svg>
  );
};
